import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Utils } from '@tools/lib/utils';
import { Observable } from 'rxjs';
import { isTokenExpired } from '@vumaex/ngx-toolbox';
import { HEADER_AUTH } from './consts';
import { ConfigService } from '@app/root-services/config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private _authToken: string;
	constructor(
		private readonly configService: ConfigService,
	) {}

	public get authToken(): string {
		this._authToken = this._authToken || Utils.bearer(this.configService.getToken);
		return !this._authToken || !this.configService.getToken || isTokenExpired(this._authToken) ? null : this._authToken;
	}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const setHeaders: { [name: string]: string } = {
			'x-proxy-request': 'frontend',
		};
		if (!this._authToken || isTokenExpired(this._authToken))
			// tslint:disable-next-line:no-unused-expression
			this.authToken;
		if (!req.headers.has(HEADER_AUTH) && this._authToken)
			setHeaders[HEADER_AUTH] = this._authToken;
		return next.handle(req.clone({ setHeaders, reportProgress: true }));
	}
}
